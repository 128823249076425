import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { message } from "antd";
import logo from "../assest/iBooming-logo.png";
import icon from "../assest/facebook.png";
import icon1 from "../assest/instagram-fill.png";
import tiktok from "../assest/tiktok.png";

import wi from "../assest/ws.png";
import wi2 from "../assest/ws2.png";
import wi3 from "../assest/ws3.png";
import wi4 from "../assest/ws4.png";
import wi5 from "../assest/ws5.png";
import country1 from "../assest/country1.png";
import country2 from "../assest/country2.png";
import country3 from "../assest/country3.png";
import country4 from "../assest/country4.png";
import country5 from "../assest/country5.png";
import country6 from "../assest/country6.png";
import ftBrand from "../assest/footer_brand2.png";
import wechat from "../assest/wechat.png";
import wechat1 from "../assest/wechat1.png";
import whats1 from "../assest/whats1.png";
import whats2 from "../assest/wi2.png";
import whats3 from "../assest/wi3.png";
import whats5 from "../assest/wi5.png";
import copyimg from "../assest/copy.png";
import copy from "copy-to-clipboard";
let timer = null;
export default function AppFooter() {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const onCopy = (num) => {
    copy(num);
    message.success({
      content: intl.formatMessage({
        id: "pages.home.复制成功",
        defaultMessage: "复制成功",
      }),
      className: "app-toast-warp",
      style: {
        marginTop: "47vh",
      },
    });
  };
  // TODO:保存到相册看看有没有更好的方法
  // https://www.baidu.com/s?wd=h5%E5%9B%BE%E7%89%87%E7%82%B9%E5%87%BB%E4%BF%9D%E5%AD%98%E5%88%B0%E7%9B%B8%E5%86%8C&rsv_spt=1&rsv_iqid=0xf6b12ebf000162c8&issp=1&f=8&rsv_bp=1&rsv_idx=2&ie=utf-8&rqlang=cn&tn=baiduhome_pg&rsv_enter=1&rsv_dl=tb&oq=h5%25E5%259B%25BE%25E7%2589%2587%25E7%2582%25B9%25E5%2587%25BB%25E6%2594%25BE%25E5%25A4%25A7%25E4%25BF%259D%25E5%25AD%2598&rsv_btype=t&inputT=8957&rsv_t=512bLMYC0tiaWQsUWuvZ7gfWZdk%2F3hOBdeFm8qdxyR445bIBhydq%2BFrmzhw1%2F5XFOwow&rsv_pq=de65df3e005189e1&rsv_sug3=87&rsv_sug1=70&rsv_sug7=100&rsv_sug2=0&rsv_sug4=9474
  const savePic = (id) => {
    let img = document.getElementById("saveImg" + id);
    const Url = img.src; //图片路径，也可以传值进来
    let triggerEvent = "touchstart"; //指定下载方式
    let blob = new Blob([""], { type: "application/octet-stream" }); //二进制大型对象blob
    let url = URL.createObjectURL(blob); //创建一个字符串路径空位
    let a = document.createElement("a"); //创建一个 a 标签
    a.href = Url; //把路径赋到a标签的href上
    a.download = Url.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
    let e = new MouseEvent(
      "click",
      (true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    );
    a.dispatchEvent(e);
    //释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
    URL.revokeObjectURL(url);
    /* 
	//这段好像并不影响，所以我不用，注释掉
	var imgs = document.getElementsByTagName("img");
	for(var i = 0,o;o = imgs[i];i++){
		o.addEventListener(triggerEvent,function(){
			var newurl = this.getAttribute("src");
			saveAs(newurl);
		},false);
	} */
  };

  return (
    <div className="app-footer">
      <h3 className="sub-title">
        <FormattedMessage id="pages.home.联系我们" defaultMessage="联系我们" />
      </h3>
      {/* 联系我们 */}
      <div className="app-contact">
        {/* 印尼 */}
        <div>
          <div className="country-info">
            <img src={country1} alt="" />
            <span>
              <FormattedMessage id="pages.home.印尼" defaultMessage="印尼" />
            </span>
          </div>
          <div className="flexB">
            <img src={wi} id="saveImg1" onClick={() => savePic("1")} alt="" />
            <div className="fnML24">
              <h3 className="title">
                <img src={whats1} alt="" />
                WhatsApp
              </h3>
              <p className="phone">
                +62 87767073446
                <img
                  onClick={() => onCopy("+62 87767073446")}
                  className="app-middle"
                  src={copyimg}
                />
              </p>
            </div>
          </div>
        </div>
        {/* 泰国 */}
        <div>
          <div className="country-info">
            <img src={country2} alt="" />
            <span>
              <FormattedMessage id="pages.home.泰国" defaultMessage="泰国" />
            </span>
          </div>
          <div className="flexB">
            <img src={wi2} id="saveImg2" onClick={() => savePic("2")} alt="" />
            <div className="fnML24">
              <h3 className="title">
                <img src={whats2} alt="" />
                LINE
              </h3>
              <p className="phone">
                +66 621768356
                <img
                  onClick={() => onCopy("+66 621768356")}
                  className="app-middle"
                  src={copyimg}
                />
              </p>
            </div>
          </div>
        </div>
        {/* 越南 */}
        <div>
          <div className="country-info">
            <img src={country3} alt="" />
            <span>
              <FormattedMessage id="pages.home.越南" defaultMessage="越南" />
            </span>
          </div>
          <div className="flexB">
            <img src={wi3} id="saveImg3" onClick={() => savePic("3")} alt="" />
            <div className="fnML24">
              <h3 className="title">
                <img src={whats3} alt="" />
                Zalo
              </h3>
              <p className="phone">
                +84 587189402
                <img
                  onClick={() => onCopy("+84 587189402")}
                  className="app-middle"
                  src={copyimg}
                />
              </p>
            </div>
          </div>
        </div>
        {/* 马来西亚 */}
        <div>
          <div className="country-info">
            <img src={country4} alt="" />
            <span>
              <FormattedMessage
                id="pages.home.马来西亚"
                defaultMessage="马来西亚"
              />
            </span>
          </div>
          <div className="flexB">
            <img src={wi4} alt="" id="saveImg4" onClick={() => savePic("4")} />
            <div className="fnML24">
              <h3 className="title">
                <img src={whats1} alt="" />
                WhatsApp
              </h3>
              <p className="phone">
                +60 175703956
                <img
                  onClick={() => onCopy("+60 175703956")}
                  className="app-middle"
                  src={copyimg}
                />
              </p>
            </div>
          </div>
        </div>
        {/* 菲律宾 */}
        <div>
          <div className="country-info">
            <img src={country5} alt="" />
            <span>
              <FormattedMessage
                id="pages.home.菲律宾"
                defaultMessage="菲律宾"
              />
            </span>
          </div>
          <div className="flexB">
            <img src={wi5} id="saveImg5" onClick={() => savePic("5")} alt="" />
            <div className="fnML24">
              <h3 className="title">
                <img src={whats5} alt="" />
                Viber
              </h3>
              <p className="phone">
                +63 9660614731
                <img
                  onClick={() => onCopy("+63 9660614731")}
                  className="app-middle"
                  src={copyimg}
                />
              </p>
            </div>
          </div>
        </div>
        {/* 中国 */}
        <div>
          <div className="country-info">
            <img src={country6} alt="" />
            <span>
              <FormattedMessage id="pages.home.中国" defaultMessage="中国" />
            </span>
          </div>
          <div className="flexB">
            <img
              src={wechat}
              id="saveImg6"
              onClick={() => savePic("6")}
              alt=""
            />
            <div className="fnML24">
              <h3 className="title">
                <img src={wechat1} alt="" />
                <FormattedMessage
                  id="pages.home.企业微信"
                  defaultMessage="企业微信"
                />
              </h3>
              {/* <p className="phone">iBooming</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="app-footer-border" style={{ marginTop: "0.12rem" }}></div>
      {/* 合作媒体 */}
      <h3 className="sub-title">
        <FormattedMessage id="pages.home.合作媒体" defaultMessage="合作媒体" />
      </h3>
      <img className="app-brand-img" src={ftBrand} alt="" />
      <div className="app-footer-border"></div>
      <div>
        <img className="logo" src={logo} alt="iBooming" />
      </div>
      <div className="icons">
        <a href="https://www.facebook.com/iBoomingOfficial">
          <img src={icon} alt="" />
        </a>
        <a href="https://www.instagram.com/ibooming_id">
          <img src={icon1} alt="" />
        </a>
        <a href="https://www.tiktok.com/@iboomingofficial">
          <img src={tiktok} alt="" />
        </a>
      </div>
      <p className="email">
        <FormattedMessage id="pages.home.电子邮箱" defaultMessage="电子邮箱" />
        {": "}
        support@iboomingglobal.com{" "}
        <img
          onClick={() => onCopy("support@iboomingglobal.com")}
          className="middle"
          src={copyimg}
          alt="ibooming.care@gmail.com"
        />
      </p>
      <div>
        <a href="/privacy/index.html" className="agree">
          Privacy policy
        </a>
        <a href="/tc/index.html" className="agree">
          Term of service
        </a>
      </div>
      {/* <p className="text">Website develop by PT Tik Star Media</p> */}
      <p
        className="text"
        style={{
          paddingBottom: 20,
        }}
      >
        Website developed by iBooming
      </p>
      {show && (
        <div className="toast-warp">
          <div className="toast-main">
            <FormattedMessage
              id="pages.home.复制成功"
              defaultMessage="Copy successfully"
            />
          </div>
        </div>
      )}
    </div>
  );
}
